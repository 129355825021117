<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const REALIZATIONS = '2KsbXxOEGDazR8dnq3Rz0g'
  export const STORE_NAMESPACE_REALIZATIONS = 'page/realizations'

  export default {
    name: 'Realizations',

    metaInfo: { title: 'Realizacje' },

    extends: View,

    mixins: [
      LoadSections([
        'sub-hero',
        'realizations-description',
        'realizations-features',
        'realizations-slider',
        'contact-us',
        'trusted-us',
        'footer',
      ]),
      FetchPage(REALIZATIONS, STORE_NAMESPACE_REALIZATIONS),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
